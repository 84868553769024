<template>
  <section class="gm-map" :style="{ height: props.mapHeight ?? '500px' }">
    <base-error-backdrop v-if="isMapError" />
    <div
      id="gmmap"
      ref="google_map"
      :style="{ height: props.mapHeight ?? '500px' }"
      v-bind="$attrs"
    />
    <div v-if="props.isInfo" id="gm-info">
      <div class="gm-info">
        <div class="gm-info-img">
          <img :src="props.infoObject?.img" alt="" />
        </div>
        <div class="gm-info-grid">
          <p class="font--b4 text-black-monochrome">
            {{ props.infoObject?.title }}
          </p>
          <span class="flexbox">
            <base-button
              size="round-sm"
              round
              icon-name="base/MapPin"
              variant="dark"
              @click="openGoogleMap(props.coords)"
            />
            <p class="font--b6 text-black-monochrome-60">
              {{ props.infoObject?.subtitle }}
            </p>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {
  useGoogleMapHTML,
  initializeGoogleMap,
} from "~/utilities/composables/useGoogleMaps";
import openGoogleMap from "~/utilities/helpers/map/openGoogleMap";

interface Props {
  coords: number[] | null | undefined;
  isInfo: boolean;
  infoObject?: {
    img: string | undefined;
    title: string | null | undefined;
    subtitle: string | null | undefined;
  };
  mapHeight?: string;
  noControls?: boolean;
  noGesture?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  coords: () => [-8.638616, 115.144086],
  mapHeigth: "500px",
});
const google_map = ref<HTMLElement | null>(null);
const isMapError = ref(false);
const loadCounter = ref(0);
const mapH = computed(() => props.mapHeight);

const loadMap = async () => {
  if (!google_map.value) {
    isMapError.value = true;
    return;
  }
  loadCounter.value++;
  useGoogleMapHTML().value = google_map.value;

  const latLng = new google.maps.LatLng({
    lat: props.coords[0] || -8.638616,
    lng: props.coords[1] || 115.144086,
  });
  const map = await initializeGoogleMap({
    map_html: google_map.value,
    latLng: latLng,
    noControls: props.noControls,
    isInfo: props.isInfo,
    noGesture: props.noGesture,
  });
  if (!map) {
    isMapError.value = true;
    function reCall() {
      if (loadCounter.value < 3) {
        setTimeout(() => {
          loadMap();
        }, 4000);
      } else return;
    }
    reCall();
  } else {
    isMapError.value = false;
  }
};

onMounted(() => {
  loadMap();
});
</script>

<style scoped lang="scss">
.gm-map {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}
#gmmap {
  width: 100%;
}
</style>
